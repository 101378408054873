import { filter, compact, isEmpty, get, uniq } from 'lodash';
import { getVideoSchema } from 'client/seo/schemas/video';
import { getArticleSchema } from 'client/seo/schemas/article';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';
import { getListItemSchema } from 'client/seo/schemas/list';
import { getImageSchema } from 'client/seo/schemas/image';
import { getFAQSchema } from 'client/seo/schemas/faq';
import { getQAEntriesFromContent } from 'site-modules/shared/utils/faq';
import { extractMetadata } from 'client/data/cms/content';
import { getFullImageUrl, MAX_WIDTH } from 'client/utils/image-helpers';
import { SUB_CATEGORIES } from 'site-modules/shared/constants/editorial/research-article';
import { BASE_BREADCRUMBS } from 'site-modules/shared/constants/editorial/editorial';
import { isCarNews } from 'site-modules/editorial/utils/car-news';
import { getHowToSchema } from 'client/seo/schemas/how-to';

export class EditorialSeo {
  /**
   * Category content and article content are objects editorial content parse results in, these should
   * be contentable items (has metadata method, children method, child method, etc.
   * Please check client/data/cms/content.js parseContent method for more details.
   *
   * @param {{}} categoryContent
   * @param {{}} articleContent
   */
  constructor(categoryContent, articleContent) {
    this.categoryContent = categoryContent;
    this.articleContent = articleContent;
  }

  /**
   * Abstract get hero content links for editorial seo: use hero-content entry
   *
   * @return {*|{children}|{metadata, links}}
   */
  getHeroContentLinks() {
    const heroContent = this.articleContent.child('hero-content');
    return heroContent && heroContent.links();
  }

  /**
   * Finds image object in article links
   *
   * @return {Object}
   */
  getImageFromArticleLinks() {
    const articleLinks = this.articleContent.links();
    return articleLinks.find(link => link.href.includes('photo'));
  }

  /**
   * Finds image object in the article content
   *
   * @return {Object}
   */
  getImageFromArticleContent() {
    const zeroEntry = this.articleContent.hasChild('0') && this.articleContent.child('0');
    const img = /<img [^>]*src="([^"]*)" [^>]*alt="([^"]*)" [^>]*>/gm.exec(zeroEntry.content);
    return img && { href: img[1], title: img[2] };
  }

  /**
   * Normalize image object to have correct uri to picture instead of dam:// stuff
   * Please consider passing only props you really need in schema inside for image object
   */
  // eslint-disable-next-line class-methods-use-this
  getImageWithCorrectUri(image) {
    return getImageSchema({
      ...image,
      uri: getFullImageUrl(image.href, MAX_WIDTH),
    });
  }

  /**
   * JSON LD Schema for Article
   *
   * @param {string} authorName - article author's name
   * @param {string} subCategory - sub category of the page
   * @param {string} category - category of the page
   * @return {*}
   */
  getArticlePageSchema(authorName, subCategory = '', categoryName = '') {
    const heroImages = this.getHeroContentLinks();
    const image = heroImages[0] || this.getImageFromArticleLinks() || this.getImageFromArticleContent();
    let articleSchemaType =
      (subCategory && subCategory.replace(/_/g, '-') === SUB_CATEGORIES.firstImpressions) || isCarNews(categoryName)
        ? 'NewsArticle'
        : 'Article';

    if (articleSchemaType === 'NewsArticle' && this.articleContent?.id?.match(/first-drive-review$/)) {
      articleSchemaType = 'ReviewNewsArticle';
    }

    const articleBody = this.getArticleBodyFromContent();
    let speakableSelectors;

    if (articleBody.includes('ul class="list-style')) {
      speakableSelectors = ['ul.list-style li'];
    }

    return getArticleSchema({
      articleObject: this.articleContent,
      image: isEmpty(image) ? undefined : this.getImageWithCorrectUri(image),
      author: authorName,
      articleType: articleSchemaType,
      articleBody,
      speakableSelectors,
      keywords: this.getSchemaKeywordsFromContent(),
    });
  }

  /**
   * JSON LD Schema for photo entries
   *
   * @return {*}
   */
  getPhotoBlocksSchema() {
    const photoBlocks = filter(this.articleContent.children(), { id: 'photo' });
    return (
      photoBlocks.length &&
      photoBlocks.reduce(
        (photoSchemas, photoBlock) => photoSchemas.concat(photoBlock.links().map(this.getImageWithCorrectUri)),
        []
      )
    );
  }

  /**
   * JSON LD Schema for video entries
   *
   * @return {*}
   */
  getVideoBlocksSchema() {
    const videoBlocks = filter(this.articleContent.children(), { id: 'video' });
    return (
      videoBlocks.length &&
      compact(
        videoBlocks.map(video =>
          getVideoSchema(
            extractMetadata(
              [
                'videoDescription',
                'videoTitle',
                'videoId',
                'uploadDate',
                'thumbnailURL',
                'videoTranscript',
                'youtube-videoid',
              ],
              video
            )
          )
        )
      )
    );
  }

  /**
   * Get breadcrumbs data array ready for getBreadcrumbSchema method to get complete json+ld for breadcrumbs
   *
   * @param {string} categoryName - current category's name
   * @param {[]} extraItems - array of objects that follow breadcrumb item pattern ({ href: '//', title: '' })
   * @return {[*,*]}
   */
  getBreadcrumbs({ categoryName, extraItems = [], subCategoryName }) {
    const category = isCarNews(categoryName)
      ? this.categoryContent.child(subCategoryName)
      : this.categoryContent.child(`category-${categoryName}`);
    const breadcrumbLinks = category.child('breadcrumb').links();

    if (breadcrumbLinks.length) {
      return getBreadcrumbSchema([...breadcrumbLinks, ...extraItems]);
    }

    return getBreadcrumbSchema([
      ...(isCarNews(categoryName) ? BASE_BREADCRUMBS.CAR_NEWS_SEO : BASE_BREADCRUMBS.RESEARCH),
      ...extraItems,
    ]);
  }

  /**
   * JSON LD Schema for hero carousel entry
   *
   * @return {*}
   */
  getPhotoCarouselJsonLd() {
    const heroContentLinks = this.getHeroContentLinks();
    return (
      heroContentLinks &&
      heroContentLinks
        .slice(0, 4) // Only 4 we need for hero carousel photos
        .map(this.getImageWithCorrectUri)
    );
  }

  getFAQSchema(canonical) {
    const content = this.articleContent.child('faq');
    return getFAQSchema({ entries: getQAEntriesFromContent(content), canonical, generateLearnMoreLinks: true });
  }

  getSubSchemaFromContent(schemaEntry) {
    return {
      [schemaEntry.id]: {
        ...schemaEntry.getAllMetadata(),
        ...schemaEntry.children().reduce(
          (schemaChildren, subSchemaEntry) => ({
            ...schemaChildren,
            ...this.getSubSchemaFromContent(subSchemaEntry),
          }),
          {}
        ),
      },
    };
  }

  getSchemasFromContent() {
    return this.articleContent
      .child('schema')
      .children()
      .map(schemaEntry => Object.values(this.getSubSchemaFromContent(schemaEntry))[0]);
  }

  getArticleBodyFromContent() {
    return this.articleContent
      .children()
      .filter(child => get(child, 'id', '').includes('html'))
      .map(child => get(child, 'content', ''))
      .join('')
      .replace(/<style>.*<\/style>/, '');
  }

  getSchemaKeywordsFromContent() {
    const categories = get(this.articleContent, 'categories', []);
    const vehicles = get(this.articleContent, 'vehicles', []);
    const vehiclesKeywords = vehicles.map(vehicle => compact(Object.values(vehicle)).join(' '));
    return uniq([...categories, ...vehiclesKeywords]).join(', ');
  }

  getListSchema({ canonical, title }) {
    return this.articleContent.hasChild('vehicles')
      ? getListItemSchema({
          items: this.articleContent
            .child('vehicles')
            .children()
            .map((entry, ind) => ({
              position: ind + 1,
              name: entry.metadata('vehicleTitle').value(),
              url: `https://www.edmunds.com${entry.metadata('vehicleURL').value()}`,
            })),
          name: title,
          pageUrl: canonical,
        })
      : null;
  }

  getHowToSchema({ title }) {
    const howToSchemaEntries = this.articleContent.children().filter(({ id }) => id?.includes('how-to-schema'));

    return howToSchemaEntries?.length
      ? howToSchemaEntries.map(howToSchemaEntry =>
          getHowToSchema({
            title,
            tools: howToSchemaEntry
              .metadata('tools')
              .value()
              .split('|'),
            supplies: howToSchemaEntry
              .metadata('supplies')
              .value()
              .split('|'),
            steps: howToSchemaEntry.children().map(stepEntry => ({
              name: stepEntry.metadata('name').value(),
              text: stepEntry.metadata('text').value(),
              image: stepEntry.metadata('image').value(),
            })),
          })
        )
      : null;
  }
}
