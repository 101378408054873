import { SCHEMA_URL } from 'site-modules/shared/pages/seo';

export function getHowToSchema({ title, tools, supplies, steps }) {
  if (!steps?.length) {
    return null;
  }

  return {
    '@context': SCHEMA_URL,
    '@type': 'HowTo',
    name: title,
    ...(tools?.length ? { tool: tools.map(tool => ({ '@type': 'HowToTool', name: tool })) } : {}),
    ...(supplies?.length ? { supply: supplies.map(supply => ({ '@type': 'HowToSupply', name: supply })) } : {}),
    step: steps.map(({ name, text, image }, index) => ({
      '@type': 'HowToStep',
      name,
      position: `${index + 1}`,
      itemListElement: [{ '@type': 'HowToDirection', position: '1', duringMedia: image, text }],
    })),
  };
}
